<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl lg:w-full w-5/6 lg:mx-0 mx-auto">

      <h2 class="text-2xl font-semibold py-4 border-b-2 border-red-600">
        Board of Directors’ Remuneration (Public Listed)
      </h2>

      <img
        src="../../assets/img/tools_Board_of_Directors_Remuneration.jpg"
        alt="Super Compensation Banner"
        class="object-cover w-full lg:h-96 h-80 mt-4"
      >
        <p class="py-4">
          Benchmarking Board of Directors’ Pay for listed companies in APAC – What is comparable?
        </p>
        <br>
        <p class="py-4">
          Hong Kong and Singapore are credited as two of the most
          desirable markets for public listing of companies.
          Remuneration Committee would need external market
          remuneration data of relevant competitors to make
          sound decisions on how much to pay their company directors
          and how to structure their pay package.
        </p>
        <br>
        <p class="py-4">
          MRC has been refreshing our Board of Directors’
          Remuneration database in Hong Kong and Singapore
          every year.
          Our database captures:
        </p>
        <ul class="px-4 pb-4 list-disc text-red-700">
          <li>Thousands of listed companies in Hong Kong and Singapore</li>
          <li>Executive Directors (ED), Non Executive Directors (NED) and
            Independent Non Executive Directors (INED)</li>
          <li>CEO and C Level Executives /Company Head, Chairman</li>
          <li>Directors’ fees, Basic salary, Allowances,Benefits-in-kind, Discretionary bonus,
              Equity compensation, Long-term cash, Pension</li>
          <li>Unlimited peer-cut and job pricing</li>
        </ul>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
